import React from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import styled from "@emotion/styled"
import { Flex, Text } from "@theme-ui/components"
import { Link, navigate } from "gatsby"

import Image from "../../new-ui/image"
import Layout from "../../layouts"
import InputField from "../../new-ui/input-field"
import Button from "../../new-ui/button"
import Medusa from "../../../services/api"
import { trackUserSignedUp } from "../../../services/analytics"
import { useNotificationContext } from "../../../context/NotificationContext"
import { useStore } from "../../../context/NewStoreContext"

const Container = styled(Flex)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  flex: 1;
`

const ImageContainer = styled(Flex)`
  flex: 1;
  flex-basis: 100%;
  ${(props) => props.theme.breakpointsLegacy.desktop} {
    flex-basis: 55%;
  }
`

const LoginContainer = styled(Flex)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  margin: 0 20px;
  ${(props) => props.theme.breakpointsLegacy.desktop} {
    flex-basis: 45%;
    position: initial;
    background-color: ${(props) => props.theme.colors.grayscale[200]};
    margin: 0px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  > div,
  button {
    margin-bottom: 8px;
  }
  ${(props) => props.theme.breakpointsLegacy.desktop} {
    max-width: 334px;
  }
`

const SignUp = ({ data }) => {
  let ValidationSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  })

  const { pushNotification } = useNotificationContext()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validateOnBlur: true,
    validateOnChange: false,

    onSubmit: async (values) => {
      const { email, password, firstName, lastName } = values

      Medusa.customers
        .create({
          email: email,
          password: password,
          first_name: firstName,
          last_name: lastName,
        })
        .then(({ data }) => {
          trackUserSignedUp(data.customer)
          navigate("/account/sign-in")
        })
        .then(() =>
          pushNotification({
            id: "create-account",
            body: "Success",
            dismiss: 3000,
          })
        )
        .catch((err) => {
          if (err.response.status === 409) {
            pushNotification({
              id: "customer-exists",
              body: "Customer already exists",
              dismiss: {
                duration: 3000,
              },
            })
          } else {
            pushNotification({
              id: "create-account",
              body: "Sorry, something went wrong. Make sure the input is correct and try again",
              dismiss: 3000,
            })
          }
        })
    },
    validationSchema: ValidationSchema,
  })

  return (
    <Layout hideFooter={true}>
      <Container>
        <ImageContainer>
          {data && <Image image={data.tile.backgroundImage.gatsbyImageData} />}
        </ImageContainer>
        <LoginContainer>
          <Text
            as="h1"
            sx={{
              textAlign: "center",
              fontSize: "27px",
              color: "primary",
              mb: "2em",
              fontWeight: "normal",
            }}
          >
            Welcome to Tekla. Create your account here
          </Text>
          <Form onSubmit={formik.handleSubmit}>
            <Flex>
              <InputField
                id="firstName"
                name="firstName"
                type="text"
                placeholder={"First name"}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.firstName && formik.errors.firstName}
                sx={{ marginRight: "2px", flex: 1 }}
              />
              <InputField
                id="lastName"
                name="lastName"
                type="text"
                placeholder={"Last name"}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.lastName && formik.errors.lastName}
                sx={{ marginLeft: "2px", flex: 1 }}
              />
            </Flex>
            <InputField
              id="email"
              name="email"
              type="text"
              placeholder={"E-mail"}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
            />
            <InputField
              id="password"
              name="password"
              type="password"
              placeholder={"Password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && formik.errors.password}
            />
            <InputField
              id="confirmPassword"
              name="confirmPassword"
              type="password"
              placeholder={"Confirm Password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
            <Button
              type="submit"
              disabled={
                formik.values.password !== formik.values.confirmPassword
              }
            >
              Sign up
            </Button>
          </Form>
          <Flex
            sx={{
              position: "absolute",
              bottom: 100,
              flexDirection: "column",
              textAlign: "center",
              a: {
                color: "primary",
              },
            }}
          >
            <Text>Already have a Tekla account?</Text>
            <Link to={"/account/sign-in"}>Sign in</Link>
          </Flex>
        </LoginContainer>
      </Container>
    </Layout>
  )
}

export default SignUp
