import React from "react"
import { graphql } from "gatsby"

import SignUp from "../../components/account/pages/sign-up"

const SignUpPage = ({ data }) => {
  return <SignUp data={data} />
}

export default SignUpPage

export const pageQuery = graphql`
  {
    tile: contentfulTileOld(
      title: { eq: "account-signup" }
      node_locale: { eq: "en-US" }
    ) {
      id
      backgroundImage {
        gatsbyImageData
      }
    }
  }
`
